<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Пользователи: Клиенты</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Клиенты</h5>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <a-input ref="searchInput" v-model="search" placeholder="Поиск">
            <a-icon slot="prefix" type="search" />
            <a-tooltip slot="suffix" title="Поиск по имени, фамилии, почте, номеру телефона">
              <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </a-input>
        </div>
        <div v-if="!isManager" class="d-flex flex-column justify-content-center ml-3">
          <a class="btn btn-primary" @click="$router.push({ path: `/users/clients/${0}` })">Добавить</a>
        </div>
      </div>
      <div class="card-body">
        <clients-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @removeItem="removeUser" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientsTable from '@/components/users/ClientsTable'

import users from '@/mixins/users'

export default {
  name: 'users',
  mixins: [users],
  data() {
    return {
      list: [],
      search: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
      loading: false,
      types: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page,
        search: this.search ? this.search : null,
      }

      const users = (await this.$services.get('admin/users', { params })).data.data.values

      this.list = users.data
      this.pagination = {
        current: users.pagination.current_page,
        total: users.pagination.total,
        pageSize: users.pagination.per_page,
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
  watch: {
    search() {
      this.pagination.current = null
      this.getList()
    },
  },
  components: { ClientsTable },
}
</script>

<style scoped>

</style>
